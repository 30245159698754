import * as React from 'react'
import { CheckGreen } from '../components/images/svg'
import { PlanInfo } from './Plans'


interface Props {
  plan: PlanInfo
}

interface PlanTextProps {
  label?: string
  value: number | string
  lineBreak? : boolean
}

interface PlanCostProps {
  plan: PlanInfo
}

const PlanItemText = ({ label, value, lineBreak }: PlanTextProps): JSX.Element  => (
  <p className="card-text">
    <CheckGreen/>
      { lineBreak ? 
        <span className="break">{label} <b>{value === 0 ? 'Not supported' : value === -1 ? 'Unlimited' : value }</b></span> :
        <span>{label} <b>{value === 0 ? 'Not supported' : value === -1 ? 'Unlimited' : value }</b></span> 
      }
  </p>
)

const PlanItemTitle = ({ plan }: PlanCostProps): JSX.Element  => (
  <div className="card-header">
    <p className="cost">{plan.name}</p>
    <p className="card-title">
      { plan.costWon }{ plan.cost > 0 && <span className="suffix"> + @</span> }
    </p>
    <p className="cost-helper">{plan.cost > 0 ? '/month' : '-'}</p>
  </div>
)

const PlanItemContent = ({ plan } : Props): JSX.Element  => (
  <div className="card-body">
    <PlanItemText label={'Simultaneous channel : '} value={plan.maxChannel} />
    <PlanItemText label={'Max number of participants : '} value={plan.maxParticipantText} />
    <PlanItemText label={'Video retention period : '} value={plan.retentionPeriod > 0 ? `${plan.retentionPeriod} month` : plan.retentionPeriod} />
    <PlanItemText label={'Max number of Uploaded Video (and Compressed Video) : '} value={plan.maxVod} lineBreak={true} />
    <PlanItemText value={plan.supportableText} />
  </div>
)

class PlanItem extends React.Component<Props> {
  public render = (): JSX.Element => {
    const p = this.props.plan

    return (
      <div className="card plan-item">
        <PlanItemTitle plan={p} />
        <PlanItemContent plan={p} />
      </div>
    )
  }
}

export default PlanItem;

