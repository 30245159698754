import { Config } from "../utils"
import { errorHandler, liveClient } from "../utils/Client"
import { currencyFormat } from "../utils/Format"

export type PlanName = 'Free' | 'Business' | 'Basic' | 'Pro'

export type Plan = {
  id: number
  name: PlanName
  channel_count: number
  chat_participants: number
  retention_period: number
  vod_count: number
  expose_goods_count: number
  resolutions: string[]
  support: number
  support_cdn: number
  cost: number
  created_at: number
}

export class PlanInfo {
  id: number
  name: string
  maxChannel: number
  maxParticipant: number
  retentionPeriod: number
  maxVod: number
  maxExposeGoods: number
  resolutions: string[]
  supportable: boolean
  cost: number

  maxChannelText: string
  maxParticipantText: string
  retentionPeriodText: string
  maxVodText: string
  maxExposeGoodsText: string
  resolutionsText: string
  supportableText: string
  costText: string
  costWon: string

  constructor(json: Plan) {
    this.id = json.id
    this.name = json.name
    this.maxChannel = json.channel_count
    this.maxParticipant = json.chat_participants
    this.retentionPeriod = json.retention_period
    this.maxVod = json.vod_count
    this.maxExposeGoods = json.expose_goods_count
    this.resolutions = json.resolutions
    this.supportable = json.support == 1 ? true : false
    this.cost = json.cost
    this.setTexts()
  }

  setTexts = (): void => {
    this.maxParticipantText = `${currencyFormat(this.maxParticipant)}`
    this.supportableText = this.supportable ? '1:1 fully Support' : 'Not supported'
    this.costWon = this.cost === 0 ? 'Free' : `${this.costFormat()} won`
  }

  getNumberToText = (num: number): string => {
    return num === -1 ? 'Unlimited' : num === 0 ? 'Not supported' : '' + num
  }

  getRetentionPeriod = (num: number): string => {
    return num === -1 ? 'Unlimited' : num === 0 ? 'Not supported' : num + ' month'
  }

  costFormat = (): string => {
    return currencyFormat(this.cost * 10000)
  }
}

export const getPlans = (callback: (list: PlanInfo[]) => void): void => {
  const param: {} = { q: 'all' }
  
  liveClient().setAccessToken(Config.GUEST_TOKEN).get("/v2/plans", param).then((response) => {
    console.log(response)

    const plans: PlanInfo[] = []
    for (const p of response.data) {
      plans.push(new PlanInfo(p))
    }
    callback(plans)
  }, errorHandler)
}