import * as React from 'react';

export default function CheckGreen(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H12V12H0z"
                transform="translate(-410 -640) translate(390 474) translate(20 166)"
              />
              <path
                fill="#3FC800"
                d="M2.95 1.95c.552 0 1 .447 1 1v3h5c.552 0 1 .447 1 1 0 .552-.448 1-1 1h-6c-.553 0-1-.448-1-1v-4c0-.553.447-1 1-1z"
                transform="translate(-410 -640) translate(390 474) translate(20 166) rotate(-45 5.95 4.95)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
