import * as React from 'react'
import { PlanInfo } from './Plans'
import PlanItem from './PlanItem'
import Button from '../components/button/Button'
import { Config } from '../utils';
import { openNewPage } from '../utils/browser';


interface Props {
  list: PlanInfo[]
}

class PlanContent extends React.Component<Props> {
  onClick = (): void => {
    openNewPage(`${Config.SUPPORT_EMAIL}`);
  }

  public render = (): JSX.Element => {
    const list = this.props.list.filter(p => p.cost > 0).map((p, index) => (
      <PlanItem key={index} plan={p} />
    ))

    return (
      <div className="plan-container">
        <div className="plans">
          { list }
        </div>
        <div className="plan-desc">
          The above plans are estimates(based on <b>RTMP</b>), this budget fluctuates according to specific requirements. (traffic, server etc.)  
          This plan is an example and other plans and detailed options contact us. (<b>WebRTC</b> type, etc.)
          <Button size="xlarge" color="red" onClick={this.onClick}>Contact Us</Button>
        </div>
      </div>
    )
  }
}

export default PlanContent;