import * as React from 'react';
import Splash from '../components/Splash';
import Footer from '../components/Footer';
import { Messages } from '../utils';
import LayoutProvider from '../components/layout/LayoutProvider';
import { getPlans, PlanInfo } from '../plan/Plans';
import PlanContent from '../plan/PlanContent';

const PlanIcon = process.env.GATSBY_IMAGE_PUBLIC_URL + 'Plan.png';

interface Props {
  location: any;
}

interface State {
  list: PlanInfo[];
}

class PlanContainer extends React.Component<Props, State> {
  state: State = {
    list: [],
  };

  componentDidMount = (): void => {
    getPlans(this.setPlans);
  };

  setPlans = (list: PlanInfo[]): void => {
    this.setState({ list });
  };

  public render(): JSX.Element {
    return (
      <LayoutProvider location={this.props.location} className="plan-container" render={(innerWidth) => 
        <>
          <div className="content-container plan-content-container">
            <Splash title={Messages.PLAN_TITLE} content={Messages.PLAN_CONTENT} image={PlanIcon} />
            <PlanContent list={this.state.list} />
          </div>
          <Footer />
        </>
      } />
    )
  }
}

export default PlanContainer